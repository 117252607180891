<template>
  <div id="app">
    <h1>Server Control Panel</h1>

    <code>mcserver.nico-assfalg.de:25569</code>

    <div id="login" v-if="!password">
      <h2>Login</h2>
      <input
        type="password"
        name="password"
        id="input_password"
        v-model="passwordInput"
        @keyup.enter="login()"
      />
      <button @click="login()">Login</button>
    </div>

    <control-panel v-if="password" :password="password" />

    <div id="logout" v-if="password">
      <button @click="logout()">Logout</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ControlPanel from "./components/ControlPanel.vue";

export default {
  name: "App",
  components: {
    ControlPanel,
  },
  methods: {
    login() {
      axios
        .post(this.$root.$data.apiBase, {
          password: this.passwordInput,
          query: "help",
        })
        .then(() => {
          this.password = this.passwordInput;
          localStorage.password = this.passwordInput;
        })
        .catch(() => {
          alert("Wrong password or Server not online");
        });
    },
    logout() {
      this.password = ''
      localStorage.password = ''
    }
  },
  data() {
    return {
      password: '',
      passwordInput: '',
    };
  },
  mounted() {
    if (localStorage.password) {
      this.password = localStorage.password
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

button {
  transition: background-color linear 0.1s;
  padding: 5px;
  background-color: white;
  border: 2px solid #2c3e50;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  outline: none !important;
}
button:hover {
  color: white;
  background-color: #2c3e50;
}
button:active {
  color: white;
  background-color: #2c3e50;
}

input,
textarea {
  border: none;
  border-bottom: 2px solid black;
  border-radius: 0;
  padding: 5px;
}

code {
  font-size: 1rem;
  font-weight: 600;
}

#logout {
  margin-top: 6rem;
}
</style>
