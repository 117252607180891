import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

let store = {
  apiBase: 'https://mcserver.nico-assfalg.de/api/query',
  apiStatus: 'https://mcserver.nico-assfalg.de/api/status',
}

new Vue({
  render: h => h(App),
  data: store
}).$mount('#app')
