<template>
  <div id="control-panel">
    <p class="statusinfo">{{ listInfo }}</p>
    <h2 class="statusheader">Set Time</h2>
    <div class="button-row">
      <button @click="setTime('day')">Day ☀</button>
      <button @click="setTime('night')">Night 🌙</button>
      <p class="statusinfo">{{ timeInfo }}</p>
    </div>
    <h2 class="statusheader">Set Weather</h2>
    <div class="button-row">
      <button @click="setWeather('clear')">Clear 🌻</button>
      <button @click="setWeather('rain')">Rain ☔</button>
      <button @click="setWeather('thunder')">Thunder ⛈</button>
    </div>
    <p class="statusinfo">{{ weatherInfo }}</p>
    <h2 class="statusheader">Set Difficulty</h2>
    <div class="button-row">
      <button @click="setDifficulty('peaceful')">Peaceful 💕</button>
      <button @click="setDifficulty('easy')">Easy 🤠</button>
      <button @click="setDifficulty('normal')">Normal 🤺</button>
      <button @click="setDifficulty('hard')">Hard 🥵</button>
    </div>
    <p class="statusinfo">{{ difficultyInfo }}</p>
    <p class="loading" v-if="showLoading">Loading...</p>
    <p class="success" v-if="showSuccess">{{ successMessage }}</p>
    <p class="warning" v-if="showWarning">{{ warningMessage }}</p>
    <h2 class="statusheader">Teleport</h2>
    <p><span>Player</span> <input type="text" v-model="teleportSrc"> to <input type="text" v-model="teleportTarget"> <button @click="teleport(teleportSrc, teleportTarget)">🛸💨</button></p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ControlPanel",
  props: {
    password: String,
  },
  data() {
    return {
      showSuccess: false,
      showWarning: false,
      successMessage: "",
      warningMessage: "",
      showLoading: false,
      timeInfo: "...",
      weatherInfo: "...",
      difficultyInfo: "...",
      listInfo: "...",
      pollingInterval: null,
    };
  },
  methods: {
    setTime(value) {
      this.showLoading = true;
      axios
        .post(this.$root.$data.apiBase, {
          password: this.password,
          query: `time set ${value}`,
        })
        .then(({ data }) => {
          this.success(data);
        })
        .catch((error) => {
          this.warning(error);
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    setWeather(value) {
      this.showLoading = true;
      axios
        .post(this.$root.$data.apiBase, {
          password: this.password,
          query: `weather ${value}`,
        })
        .then(({ data }) => {
          this.success(data);
        })
        .catch((error) => {
          this.warning(error);
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    setDifficulty(value) {
      this.showLoading = true;
      axios
        .post(this.$root.$data.apiBase, {
          password: this.password,
          query: `difficulty ${value}`,
        })
        .then(({ data }) => {
          this.success(data);
        })
        .catch((error) => {
          this.warning(error);
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    success(message) {
      (this.showSuccess = true), (this.successMessage = message);
      setTimeout(() => {
        this.showSuccess = false;
      }, 2000);
    },
    warning(message) {
      this.showWarning = true;
      this.warningMessage = message;
      setTimeout(() => {
        this.showWarning = false;
      }, 2000);
    },
    teleport(teleportSrc, teleportTarget) {
      this.showLoading = true
      axios.post(this.$root.$data.apiBase, {
        password: this.password,
        query: `tp ${teleportSrc} ${teleportTarget}`
      }).then(({ data }) => {
        this.success(data)
      }).catch(error => {
        this.warning(error)
      }).finally(() => {
        this.showLoading = false
      })
    },
    pollStatus() {
      this.pollingInterval = setInterval(() => {
        axios
          .post(this.$root.$data.apiStatus, {
            password: this.password,
          })
          .then(({ data }) => {
            this.timeInfo = data.time;
            //this.weatherInfo = data.weather
            this.difficultyInfo = data.difficulty;
            this.listInfo = data.list;
          })
          .catch((error) => {
            this.warning(error);
          });
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.pollingInterval);
  },
  created() {
    this.pollStatus();
  },
};
</script>

<style scoped>
.success {
  color: blue;
}
.warning {
  color: orange;
}
.statusinfo {
  font-size: 0.9em;
  color: gray;
}
.statusheader {
  margin-top: 1.5em;
}
</style>
